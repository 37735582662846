import React from 'react'
import TopBar from './components/TopBar'
import { Icon } from '@iconify/react'
import Services1 from '../../assests/undraw_Building_re_xfcm.png'
import Services2 from '../../assests/undraw_Credit_card_re_blml (1).png'
import Services3 from '../../assests/undraw_Online_dating_re_hu03.png'
import Services4 from '../../assests/undraw_online_transactions_02ka.png'
import Services5 from '../../assests/undraw_nakamoto_2iv6.png'
import Services6 from '../../assests/undraw_Investing_re_bov7.png'
import Services7 from '../../assests/undraw_Financial_data_re_p0fl.png'
import Services8 from '../../assests/undraw_visual_data_re_mxxo.png'
import Why1 from '../../assests/undraw_Agreement_re_d4dv.png'
import Why2 from '../../assests/undraw_two_factor_authentication_namy.png'
import Why3 from '../../assests/undraw_Security_re_a2rk.png'
import WhyBanner from '../../assests/what-we-work.png'
import CyberImage from '../../assests/shape.png'
import Footer from './components/Footer'

function Services() {
  const services = [
    {
      id: 1,
      image: Services1,
      tag: 'Bank Scams',
      details:
        'Navigating the aftermath of bank scams can be daunting, but our team is here to provide expert guidance and support. ',
    },
    {
      id: 2,
      image: Services2,
      tag: 'Credit card Scams',
      details:
        'Our expert team specializes in navigating the complexities of credit card scams, guiding individuals through the process of recovering funds lost to fraudulent transactions. ',
    },
    {
      id: 3,
      image: Services3,
      tag: 'Dating Scams',
      details:
        " Whether it's romance fraud or catfishing schemes, we offer personalized support and guidance to assist clients in reclaiming their financial losses. ",
    },
    {
      id: 4,
      image: Services4,
      tag: 'Online Transaction Scams',
      details:
        'From phishing scams to fake online stores, we leverage our expertise to assist clients in navigating the complexities of online transaction scams. ',
    },
    {
      id: 5,
      image: Services5,
      tag: 'Crypto Scam',
      details:
        "Whether it's a fraudulent ICO, phishing attack, or investment scam, we employ advanced techniques to trace and reclaim your digital assets, providing you with peace of mind and financial restitution.",
    },
    {
      id: 6,
      image: Services6,
      tag: 'Forex Scam',
      details:
        'Have you been defrauded by a forex trading scam? Our dedicated team specializes in assisting victims of forex fraud, leveraging our deep understanding of the forex market and regulatory frameworks to pursue restitution.',
    },
    {
      id: 7,
      image: Services7,
      tag: 'CFD Trading Scam',
      details:
        "Falling prey to a CFD (Contract for Difference) trading scam can be devastating, but you don't have to face it alone. Our experienced professionals have a proven track record of recovering funds for victims of CFD trading scams.",
    },
    {
      id: 8,
      image: Services8,
      tag: 'Binary Options Scam',
      details:
        ' Binary options scams can leave individuals financially devastated, but there is hope for recovery. Our team specializes in assisting victims of binary options fraud, using a combination of legal expertise and investigative techniques to pursue restitution from fraudulent brokers and trading platforms. ',
    },
  ]
  const whyChoose = [
    {
      id: 1,
      image: Why1,
      tag: 'Trusted Partner',
      details:
        'As your trusted partner in financial recovery, we provide unwavering support and expertise to guide you through the process of reclaiming lost funds and assets. ',
    },
    {
      id: 2,
      image: Why2,
      tag: 'System Security',
      details:
        'We prioritize the security of your financial data with stringent system security measures. ',
    },
    {
      id: 3,
      image: Why3,
      tag: 'User Security',
      details:
        'At the heart of our service is a commitment to user security. We understand the importance of safeguarding your sensitive information.',
    },
  ]
  return (
    <div className='services'>
      <TopBar page={'services'} />
      <div className='hero'>
        <div className='hero-wrap'>
          <h1>Our Services</h1>
          <div className='tag'>
            <p className='first'>Home</p>
            <Icon icon={'mingcute:right-line'} />
            <p className='next'>Services</p>
          </div>
        </div>
      </div>
      <div className='cardX'>
        {services.map((item) => (
          <div className='card' key={item.id}>
            <div className='one'>
              <img src={item.image} alt='' />
            </div>
            <div className='two'>
              <h2>{item.tag}</h2>
              <p>{item.details}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='trust'>
        <div className='container'>
          <div className='left'>
            <img src={WhyBanner} alt='' />
          </div>
          <div className='right'>
            <div className='top'>
              <p>Why Trust Us</p>
              <h2>
                Invest in peace of mind with our trusted expertise in financial
                recovery.
              </h2>
            </div>
            <div className='cards'>
              {whyChoose.map((item) => (
                <div className='card' key={item.id}>
                  <div className='one'>
                    <img src={item.image} alt='' />
                  </div>
                  <div className='two'>
                    <h2>{item.tag}</h2>
                    <p>{item.details}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='cyber'>
          <img src={CyberImage} alt='' />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services
