import React from 'react'
import TopBar from './components/TopBar'
import './Home.scss'
import { Icon } from '@iconify/react'
import ScamForm from './components/ScamForm'
import Footer from './components/Footer'

function Contact() {
  return (
    <div className='contact'>
      <TopBar page={'contact'} />
      <div className='hero'>
        <div className='hero-wrap'>
          <h1>Contact Us</h1>
          <div className='tag'>
            <p className='first'>Home</p>
            <Icon icon={'mingcute:right-line'} />
            <p className='next'>Contact Us</p>
          </div>
        </div>
      </div>
      <div className='contact-list'>
        <div className='card'>
          <div className='icon-tag'>
            <Icon icon={'ion:time-outline'} />
          </div>
          <h2>Working Hours:</h2>
          <p>Open Monday to Friday </p>
          <p>From 09:00 AM to 08:00 PM</p>
        </div>
        <div className='card'>
          <div className='icon-tag'>
            <Icon icon={'fluent:mail-unread-48-regular'} />
          </div>
          <h2>Email Address:</h2>
          <p>support@financialretrieval.com</p>
          <p>hello@financialretrieval.com</p>
        </div>
        <div className='card'>
          <div className='icon-tag'>
            <Icon icon={'tdesign:call'} />
          </div>
          <h2>Call: </h2>
          <p>(443) 698-8394</p>
        </div>
        <div className='card'>
          <div className='icon-tag'>
            <Icon icon={'mdi:whatsapp'} />
          </div>
          <h2>WhatsApp: </h2>
          <p>(386) 285-9336</p>
        </div>
      </div>
      <div className='contact-wrap'>
        <ScamForm />
      </div>
      <Footer />
    </div>
  )
}

export default Contact
