import React from 'react'
import Logo from '../../../assests/retri-logo (2).png'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer-wrap'>
        <div className='top'>
          <div className='card'>
            <img src={Logo} alt='logo' />
            <p>
              The most reliable and secure online platform for crptocurrency
              investment and mining
            </p>
          </div>
          <div className='card'>
            <h2>Company</h2>
            <a href='#home'>
              <p>Home</p>
            </a>
            {/* <a href='#about'>
              <p>About</p>
            </a> */}
            <a href='/services'>
              <p>Services</p>
            </a>
            <a href='/contact-us'>
              <p>Contact Us</p>
            </a>
            <a href='/faq'>
              <p>FAQ</p>
            </a>
          </div>
          <div className='card' id='contact'>
            <h2>Contact</h2>
            <a href='tel:14436988394'>
              <p>Call: (443) 698-8394</p>
            </a>
            <a href='https://wa.me/13862859336'>
              <p>WhatsApp</p>
            </a>
            <a href='mailto:support@financialretrieval.com'>
              <p>support@financialretrieval.com</p>
            </a>
          </div>
          <div className='card'>
            <h2>Legal</h2>
            {/* <Link to='/terms-of-service'>
              <p>Terms of services</p>
            </Link>
            <Link to='/privacy-and-policy'>
              <p>Privacy policy</p>
            </Link> */}
          </div>
        </div>
        <div className='bottom'>
          <p>
            © 2017. Financial retrieval is a tech firm, not a bank. Copying or
            distributing any copyrighted material from this website is strictly
            prohibited. Once you tap on certain links above, you will be
            redirected to an external website. It's important to take note that
            the privacy policies of such third-parties may differ from Financial
            retrieval's. We suggest you examine the privacy statements of these
            external websites as we are not responsible for their privacy or
            security practices.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
