const Scams = [
  'Crypto Scam',
  'Forex Scam',
  'Credit Card Scam',
  'Banking Fraud',
  'Online Scam',
  'CFD Trading Scam',
  'Binary Options Scam',
  'Dating Scam',
]

export default Scams
