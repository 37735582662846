import React from 'react'
import TopBar from './components/TopBar'
import './Home.scss'
import BannerImage from '../../assests/banner.png'
import Serve1 from '../../assests/undraw_Server_re_twwj.png'
import Serve2 from '../../assests/undraw_Server_status_re_n8ln.png'
import Serve3 from '../../assests/undraw_Mobile_encryption_re_yw3o.png'
import CyberImage from '../../assests/shape.png'
import Why1 from '../../assests/undraw_Agreement_re_d4dv.png'
import Why2 from '../../assests/undraw_two_factor_authentication_namy.png'
import Why3 from '../../assests/undraw_Security_re_a2rk.png'
import WhyBanner from '../../assests/what-we-work.png'
import Services1 from '../../assests/undraw_Building_re_xfcm.png'
import Services2 from '../../assests/undraw_Credit_card_re_blml (1).png'
import Services3 from '../../assests/undraw_Online_dating_re_hu03.png'
import Services4 from '../../assests/undraw_online_transactions_02ka.png'
import ServiceImage from '../../assests/shape2.png'
import ChooseImage from '../../assests/choose.png'
import { Icon } from '@iconify/react'
import Scams from './components/Scams'
import ScamForm from './components/ScamForm'
import Footer from './components/Footer'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate()
  const serve1 = [
    {
      id: 1,
      image: Serve1,
      tag: 'Network Security',
      details:
        'We prioritize the security of your sensitive information throughout the process. Employing robust network security measures, including encryption, firewalls, and regular audits, we mitigate risks and provide peace of mind as you reclaim lost funds and assets.',
    },
    {
      id: 2,
      image: Serve2,
      tag: 'Cloud Security',
      details:
        'Cloud Security is vital for safeguarding sensitive financial data during the recovery process. With robust measures like encryption and access controls, we ensure data integrity and confidentiality, providing peace of mind for our clients.',
    },
    {
      id: 3,
      image: Serve3,
      tag: 'Application Security',
      details:
        'This involves implementing measures to identify and mitigate security vulnerabilities, such as code vulnerabilities, authentication flaws, and injection attacks. By prioritizing Application Security, we safeguard against unauthorized access, data breaches, providing a secure and reliable user experience for our clients.',
    },
  ]
  const whyChoose = [
    {
      id: 1,
      image: Why1,
      tag: 'Trusted Partner',
      details:
        'As your trusted partner in financial recovery, we provide unwavering support and expertise to guide you through the process of reclaiming lost funds and assets. ',
    },
    {
      id: 2,
      image: Why2,
      tag: 'System Security',
      details:
        'We prioritize the security of your financial data with stringent system security measures. ',
    },
    {
      id: 3,
      image: Why3,
      tag: 'User Security',
      details:
        'At the heart of our service is a commitment to user security. We understand the importance of safeguarding your sensitive information.',
    },
  ]
  const services = [
    {
      id: 1,
      image: Services1,
      tag: 'Bank Scams',
      details:
        'Navigating the aftermath of bank scams can be daunting, but our team is here to provide expert guidance and support. ',
    },
    {
      id: 2,
      image: Services2,
      tag: 'Credit card Scams',
      details:
        'Our expert team specializes in navigating the complexities of credit card scams, guiding individuals through the process of recovering funds lost to fraudulent transactions. ',
    },
    {
      id: 3,
      image: Services3,
      tag: 'Dating Scams',
      details:
        " Whether it's romance fraud or catfishing schemes, we offer personalized support and guidance to assist clients in reclaiming their financial losses. ",
    },
    {
      id: 4,
      image: Services4,
      tag: 'Online Transaction Scams',
      details:
        'From phishing scams to fake online stores, we leverage our expertise to assist clients in navigating the complexities of online transaction scams. ',
    },
  ]

  return (
    <div className='home'>
      <TopBar page={'home'} />
      <div className='hero'>
        <div className='left'>
          <h2>World Class Security</h2>
          <h1>Recover Lost Funds and Assets</h1>
          <p>
            When it comes to retrieving lost finances, we're your trusted
            partner every step of the journey. Our dedicated team is here to
            provide reliable assistance as you navigate the process of
            reclaiming misplaced funds and assets. With our wealth of experience
            and commitment to excellence, you can rest assured that we'll work
            tirelessly to help you recover what rightfully belongs to you. Trust
            us to be your ally in reclaiming what's rightfully yours.
          </p>
          <button onClick={() => navigate('/contact-us')}>Contact Us</button>
        </div>
        <div className='right'>
          <img src={BannerImage} alt='' />
        </div>
      </div>
      <div className='serve-1'>
        {serve1.map((item) => (
          <div className='card' key={item.id}>
            <img src={item.image} alt='' />
            <h2>{item.tag}</h2>
            <p>{item.details}</p>
          </div>
        ))}
      </div>
      <div className='trust'>
        <div className='container'>
          <div className='left'>
            <img src={WhyBanner} alt='' />
          </div>
          <div className='right'>
            <div className='top'>
              <p>Why Trust Us</p>
              <h2>
                Invest in peace of mind with our trusted expertise in financial
                recovery.
              </h2>
            </div>
            <div className='cards'>
              {whyChoose.map((item) => (
                <div className='card' key={item.id}>
                  <div className='one'>
                    <img src={item.image} alt='' />
                  </div>
                  <div className='two'>
                    <h2>{item.tag}</h2>
                    <p>{item.details}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='cyber'>
          <img src={CyberImage} alt='' />
        </div>
      </div>
      <div className='counter'>
        <div className='cards'>
          <div className='card'>
            <h1>7K</h1>
            <p>Clients</p>
          </div>
          <div className='card'>
            <h1>$5.6M</h1>
            <p>Recovered</p>
          </div>
          <div className='card'>
            <h1>8.5K</h1>
            <p>Accounts retrieved</p>
          </div>
          <div className='card'>
            <h1>10K</h1>
            <p>Accounts protected</p>
          </div>
        </div>
      </div>
      <div className='services'>
        <div className='container'>
          <div className='top'>
            <h1>Our Expertise</h1>
            <p>
              Our services are tailored to assist anyone affected by financial
              fraud
            </p>
          </div>
          <div className='cards'>
            {services.map((item) => (
              <div className='card' key={item.id}>
                <div className='one'>
                  <img src={item.image} alt='' />
                </div>
                <div className='two'>
                  <h2>{item.tag}</h2>
                  <p>{item.details}</p>
                </div>
              </div>
            ))}
          </div>
          <div className='but'>
            <button onClick={() => navigate('/services')}>View More</button>
          </div>
        </div>
        <div className='serve'>
          <img src={ServiceImage} alt='' />
        </div>
      </div>
      <ScamForm />
      <div className='trust'>
        <div className='container change'>
          <div className='right'>
            <div className='top'>
              <p>Why Choose Us</p>
              <h2>Discover Our Distinctive Expertise for Your Needs</h2>
              <p className='diff'>
                Unlock Our Specialized Expertise in Financial Recovery Solutions
                Designed to Safeguard Your Assets and Restore Stability
              </p>
            </div>
            <div className='cards'>
              <ul>
                {Scams.map((item) => (
                  <li key={item}>
                    <Icon icon='line-md:check-all' />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='left'>
            <img src={ChooseImage} alt='' />
          </div>
        </div>
        <div className='cyber'>
          <img src={CyberImage} alt='' />
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Home
