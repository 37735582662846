import React, { useState } from 'react'
import Countries from '../components/Countries'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Scams from '../components/Scams'

function ScamForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [loading, setLoading] = useState(false)
  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const response = await fetch(
        'https://fcd-be-e3a65f61233d.herokuapp.com/financial/recover',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
      const responseData = await response.json()
      toast.success(responseData.message)
      reset() // Reset form fields
    } catch (error) {
      toast.error(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className='contact-us'>
      <div className='top'>
        <h1>Let’s Get Your Money Back!</h1>
        <p>
          Have you been a victim of a scam? Get a free consultation from our
          team.
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='form'>
        <div className='card'>
          <input
            type='text'
            placeholder='Name'
            {...register('name', { required: true })}
          />
          {errors.name && <span>Name is required</span>}
        </div>
        <div className='card'>
          <input
            type='email'
            placeholder='Email'
            {...register('email', { required: true })}
          />
          {errors.email && <span>Email is required</span>}
        </div>
        <div className='card'>
          <input
            type='tel'
            placeholder='Phone'
            {...register('phone', { required: true })}
          />
          {errors.phone && <span>Phone is required</span>}
        </div>
        <div className='card'>
          <input
            type='number'
            placeholder='Amount Lost'
            {...register('amountLost', { required: true })}
          />
          {errors.amountLost && <span>Amount Lost is required</span>}
        </div>
        <div className='card'>
          <select {...register('country', { required: true })}>
            <option value=''>-country-</option>
            {Countries.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
          {errors.country && <span>Country is required</span>}
        </div>
        <div className='card'>
          <select {...register('scam', { required: true })}>
            <option value=''>-scam type-</option>
            {Scams.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
          {errors.scam && <span>Scam type is required</span>}
        </div>
        <div className='butt'>
          <button type='submit' disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ScamForm
