import React, { useState } from 'react'
import Logo from '../../../assests/retri-logo.png'
import './components.scss'
import { Link } from 'react-router-dom'

function TopBar({ page }) {
  const [showNav, setShowNav] = useState('false')
  return (
    <div className='nav-wrap'>
      <div className='navbar'>
        <div className='nav_contain'>
          <div className='logo'>
            <Link to='#home'>
              <img src={Logo} alt='' />
            </Link>
          </div>
          <div
            onClick={() => setShowNav(!showNav)}
            className={showNav ? 'hamburger' : 'hamburger toggle'}
          >
            <div className='line1'></div>
            <div className='line2'></div>
            <div className='line3'></div>
          </div>
          <ul className={showNav ? 'nav_list' : 'nav_list open'}>
            <Link to='/'>
              <li
                onClick={() => {
                  setShowNav(!showNav)
                }}
                className={page === 'home' ? 'active' : ''}
              >
                Home
              </li>
            </Link>
            {/* <Link to='#about'>
              <li
                onClick={() => {
                  setShowNav(!showNav)
                }}
                className={page === 'about' ? 'active' : ''}
              >
                About Us
              </li>
            </Link> */}
            <Link to='/services'>
              <li
                onClick={() => {
                  setShowNav(!showNav)
                }}
                className={page === 'services' ? 'active' : ''}
              >
                Services
              </li>
            </Link>
            <Link to='/contact-us'>
              <li
                onClick={() => {
                  setShowNav(!showNav)
                }}
                className={page === 'contact' ? 'active' : ''}
              >
                Contact us
              </li>
            </Link>
            <Link to='/faq'>
              <li
                onClick={() => {
                  setShowNav(!showNav)
                }}
                className={page === 'faq' ? 'active' : ''}
              >
                Faq
              </li>
            </Link>
            <a href='#p'>
              <li>
                <p className='btn'> Call: (443) 698-8394</p>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TopBar
