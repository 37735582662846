import React, { useState } from 'react'
import TopBar from './components/TopBar'
import { Icon } from '@iconify/react'
import Footer from './components/Footer'

function FAQ() {
  const faqs = [
    {
      question: 'What types of scams can I seek help with?',
      answer:
        'We assist with various types of financial scams, including cryptocurrency scams, forex scams, investment fraud, phishing schemes, and more.',
    },
    {
      question: "How do I know if I've been scammed?",
      answer:
        'Common signs of a scam include promises of high returns with low risk, requests for personal or financial information, pressure to act quickly, and unregulated investment opportunities.',
    },
    {
      question: "What should I do if I've been scammed?",
      answer:
        "If you suspect you've been scammed, it's crucial to act quickly. Contact our team immediately to report the incident, gather any relevant documentation, and refrain from providing additional funds or personal information.",
    },
    {
      question: 'Can I recover my lost funds?',
      answer:
        'While every case is unique, we work diligently to recover lost funds through various channels, including legal action, negotiation with financial institutions, and cooperation with law enforcement agencies.',
    },
    {
      question: 'How long does the recovery process take?',
      answer:
        'The timeframe for recovering funds varies depending on the complexity of the case, cooperation from involved parties, and other factors. We strive to expedite the process while ensuring thorough and effective recovery efforts.',
    },
    {
      question: 'What information do I need to provide for assistance?',
      answer:
        'To begin the recovery process, we typically require details about the scam, including transaction records, communication with the scammer, and any relevant documentation.',
    },
    {
      question: 'Is there a fee for your services?',
      answer:
        'We offer a range of services tailored to meet your needs, and fees may vary depending on the complexity of the case. However, we provide transparent pricing and will discuss all costs upfront.',
    },
    {
      question: 'What steps can I take to protect myself from future scams?',
      answer:
        'We provide educational resources and guidance on how to recognize and avoid scams in the future. This includes tips on conducting due diligence, verifying investment opportunities, and staying informed about common scam tactics.',
    },
    {
      question:
        'Are my personal and financial details secure with your company?',
      answer:
        "Yes, we prioritize the security and confidentiality of our clients' information. Our team adheres to strict privacy protocols and takes measures to safeguard sensitive data throughout the recovery process.",
    },
    {
      question: 'How do I get started with your services?',
      answer:
        "Simply reach out to our team through our website or contact information provided. We'll guide you through the initial steps and discuss the best approach for your specific situation.",
    },
    {
      question: 'What are the common warning signs of a potential scam?',
      answer:
        'Warning signs may include unsolicited offers, requests for upfront payments, pressure to act quickly, promises of guaranteed returns, and lack of transparency regarding investment opportunities.',
    },
    {
      question:
        "Can I recover funds if I've been a victim of an online trading scam?",
      answer:
        'Yes, we specialize in recovering funds from online trading scams, including forex, binary options, and CFD trading. Our experienced team will assess your case and pursue all available avenues for recovery.',
    },
    {
      question: 'How do I report a scam to the authorities?',
      answer:
        "If you've been scammed, you should report the incident to your local law enforcement agency and relevant regulatory authorities. Additionally, our team can assist you in filing reports and providing evidence to support your case.",
    },
    {
      question:
        "What if I've already provided personal information to a scammer?",
      answer:
        "If you've disclosed personal information to a scammer, it's essential to take immediate action to protect yourself. Contact our team for guidance on steps to mitigate the risk of identity theft and financial fraud.",
    },
    {
      question: 'Do you offer assistance for victims of romance scams?',
      answer:
        'Yes, we provide support and assistance for victims of romance scams, including online dating scams. Our team is experienced in navigating these complex situations and working towards a positive outcome for our clients.',
    },
  ]
  const [selectedQuestion, setSelectedQuestion] = useState(faqs[0].question)
  return (
    <div className='faq'>
      <TopBar page={'faq'} />
      <div className='hero'>
        <div className='hero-wrap'>
          <h1>FAQ</h1>
          <div className='tag'>
            <p className='first'>Home</p>
            <Icon icon={'mingcute:right-line'} />
            <p className='next'>FAQ</p>
          </div>
        </div>
      </div>
      <div className='questions'>
        <div className='questions-wrap'>
          {faqs.map((item) => (
            <div
              className={
                selectedQuestion === item.question ? 'card active' : 'card'
              }
              key={item.question}
            >
              <div className='card-top'>
                <h2>{item.question}</h2>
                {selectedQuestion === item.question ? (
                  <Icon
                    icon={'zondicons:minus-outline'}
                    onClick={() => setSelectedQuestion('')}
                  />
                ) : (
                  <Icon
                    icon={'octicon:plus-circle-16'}
                    onClick={() => setSelectedQuestion(item.question)}
                  />
                )}
              </div>
              {selectedQuestion === item.question && (
                <div className='card-body'>
                  <p>{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FAQ
